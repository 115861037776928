import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { getUserDetails, isLoggedIn } from "../../utils/services/methods";
import queryString from "query-string";

const Form = props => {
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");
  const queryParams = queryString.parse(props.location.search);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (!isLoggedIn()) {
      if (typeof window === "object") {
        localStorage.setItem(
          "pendingAcceleratorApplication",
          JSON.stringify({
            formid: queryParams.id,
          })
        );
        window.location.replace(
          `${process.env.GATSBY_login_url}sso?redirect=${window.location.origin}/accelerator/form%3Fid%3D${queryParams?.id}`
        );
      }
    } else {
      const user = getUserDetails();
      setUserDetails(user);
      const pendingApplication = JSON.parse(
        localStorage.getItem("pendingAcceleratorApplication")
      );
      if (queryParams?.id) {
        setId(queryParams?.id);
      } else if (pendingApplication?.formid) {
        setId(pendingApplication?.formid);
      }
      localStorage.removeItem("pendingAcceleratorApplication");
      setLoading(false);
    }
  }, []);

  return (
    <Layout loading={loading}>
      <div>
        <iframe
          title="form assembly embed"
          src={`https://acumenacademy.tfaforms.net/${id}?accnt=${userDetails?.external_id}`}
          style={{ width: "100%" }}
          height="800"
          width="600"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Form;
